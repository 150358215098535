import React, { createContext, useState, useEffect, useContext } from "react";
import logo from "./images/logo.png";
import hmnc from "./images/hmnc.png";
import gmnc from "./images/gmnc.png";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const themes = {
    theme1: {
      "--background-color": "#425eac",
      "--button-color": "#456990",
      "--svg-color": "#7487c2",
      "--component-background-color": "#EEF1FF",
      "--authbox-color": "#fff",
      "--menu-item-color": "#ffff",
      "--text-color": "rgba(0, 0, 0,0.8)",
      "--topbar-background":
        "linear-gradient(to right, #f0f0f0, #d1e8ff, #425EAC)",
      "--card-background": "linear-gradient(to bottom, #6ab0e2, #396288)",
      "--logo-background-image": `url(${logo})`,
      "--line-color": "#E2E8F0",
      "--page-color": "#d1e8ff",
      production: {
        running: "#0D9276",
        normal: "#5356FF",
      },
    },
    theme2: {
      "--background-color": "#fa6821",
      "--button-color": "#fa6821",
      "--svg-color": "#fa9868",
      "--component-background-color": "#FEEEE5",
      "--authbox-color": "#fff",
      "--menu-item-color": "#ffff",
      "--topbar-background":
        "linear-gradient(to right, #f0f0f0, #fa9868, #F6921E, #fa6821)",
      "--logo-background-image": `url(${logo})`,
      "--card-background": "linear-gradient(to bottom, #fa6821, #fa9868)",
      "--text-color": "rgba(0, 0, 0,0.8)",
      "--line-color": "#E2E8F0",
      "--page-color": "#fa9868",
      production: {
        running: "#0D9276",
        normal: "#5356FF",
      },
    },
    light: {
      "--background-color": "#c5c4c4",
      "--button-color": "#dddddd",
      "--svg-color": "#666666",
      "--authbox-color": "#f0f0f0",
      "--component-background-color": "#f0f0f0",
      "--menu-item-color": "black",
      "--topbar-background":
        "linear-gradient(to right, #f0f0f0, #d1d7eb, #c5c4c4)",
      "--logo-background-image": `url(${logo})`,
      "--card-background": "linear-gradient(to bottom, #dddddd, #666666)",
      "--text-color": "rgba(0, 0, 0,0.8)",
      "--line-color": "#E2E8F0",
      "--page-color": "#d1d7eb",
      production: {
        running: "#0D9276",
        normal: "#5356FF",
      },
    },
    dark: {
      "--background-color": "#555555",
      "--button-color": "#666666",
      "--svg-color": "#dddddd",
      "--authbox-color": "#222222",
      "--component-background-color": "#c4beae",
      "--text-color": "rgba(0, 0, 0,0.8)",
      "--menu-item-color": "#FFFF",
      "--topbar-background":
        "linear-gradient(to right, #555555, #666666, #777777)",
      "--card-background": "linear-gradient(to bottom, #666666, #dddddd)",
      "--logo-background-image": `url(${logo})`,
      "--line-color": "#E2E8F0",
      "--page-color": "#d1d7eb",
      production: {
        running: "#0D9276",
        normal: "#5356FF",
      },
    },
  };

  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme && Object.keys(themes).includes(storedTheme)
      ? storedTheme
      : "theme1";
  });

  useEffect(() => {
    const root = document.documentElement;
    const themeStyles = themes[theme];
    Object.keys(themeStyles).forEach((key) => {
      root.style.setProperty(key, themeStyles[key]);
    });
  }, [theme]);

  const handleThemeChange = (selectedTheme) => {
    setTheme(selectedTheme);
    localStorage.setItem("theme", selectedTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, handleThemeChange }}>
      {children}
    </ThemeContext.Provider>
  );
};

import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { beAddress } from '../utils/constants';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfileData, setUserProfileData] = useState(null);
  const [profileImage, setProfileImage] = useState("https://via.placeholder.com/150");
  const session_uid = localStorage.getItem("session_uid");
  const user_uid = localStorage.getItem("user_uid");

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${beAddress}/api/user-profile`, {
        headers: {
          "session-uid": session_uid,
          "user-uid": user_uid,
        },
      });
      if (response.status === 200 && response.data.status === "Success") {
        const data = response.data.data;
        setUserProfileData(data);
        if (data.photo) {
          setProfileImage(`data:image/jpeg;base64,${data.photo}`);
          localStorage.setItem(
            "profileImage",
            `data:image/jpeg;base64,${data.photo}`
          );
        }
      }
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  }, [session_uid, user_uid]);

  return (
    <UserContext.Provider value={{ userProfileData, profileImage, setProfileImage, fetchData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

import React, { Suspense, useEffect, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
// // import Layout from "./mncLayout";
// const Layout = lazy(() => import("./mncLayout"))
// import AuthPage from "./components/mncLogin";
// import ShiftSchedule from "./components/mncManageShifts";
// import CreateSchedule from "./components/mncCreateSch";
// import HomePage from "./components/HomePage";
// import UserList from "./components/UserList";
// import UserDeviceMaps from "./components/UserDeviceMaps";
// import DeviceList from "./components/DeviceList";
// import AdminShiftView from "./components/AdminShiftView";
// import DeviceDashboard from "./components/DeviceDashboard";
// import MainDashboard from "./components/MainDashboard";
// import Settings from "./components/Settings";
import { ThemeProvider } from "./ThemeContext";
// import AddUser from "./components/AddUser";
// import AssignDevice from "./components/AssignDevice";
// import ProfileSection from "./components/user-profiles/ProfileSection";
// import ChangePassword from "./components/user-profiles/ChangePassword";
// import UpdateTheme from "./components/user-profiles/UpdateTheme";
// import MaterialInventory from "./components/MaterialInventory/MaterialInventory";
// import Test from "./components/test";
// import AddMaterial from "./components/MaterialInventory/AddMaterial";
// import JobLibrary from "./components/job-submit/JobLibrary";
// import AddJob from "./components/job-submit/AddJob";
// import JobPart from "./components/job-submit/JobPart";
import { UserProvider } from "./components/UserContext";

import './App.css';

//import SessionModal from "./components/Modal"; 

import { checkSession, sessionExtension } from "./utils/sessionexpire";

const Layout = lazy(() => import("./mncLayout"));
const AuthPage = lazy(() => import("./components/mncLogin"));
const ShiftSchedule = lazy(() => import("./components/mncManageShifts"));
const CreateSchedule = lazy(() => import("./components/mncCreateSch"));
const HomePage = lazy(() => import("./components/HomePage"));
const UserList = lazy(() => import("./components/UserList"));
const UserDeviceMaps = lazy(() => import("./components/UserDeviceMaps"));
const DeviceList = lazy(() => import("./components/DeviceList"));
const AdminShiftView = lazy(() => import("./components/AdminShiftView"));
const DeviceDashboard = lazy(() => import("./components/DeviceDashboard"));
const MainDashboard = lazy(() => import("./components/MainDashboard"));
const Settings = lazy(() => import("./components/Settings"));
const AddUser = lazy(() => import("./components/AddUser"));
const AssignDevice = lazy(() => import("./components/AssignDevice"));
const ProfileSection = lazy(() =>
  import("./components/user-profiles/ProfileSection")
);
const ChangePassword = lazy(() =>
  import("./components/user-profiles/ChangePassword")
);
const UpdateTheme = lazy(() =>
  import("./components/user-profiles/UpdateTheme")
);
const MaterialInventory = lazy(() =>
  import("./components/MaterialInventory/MaterialInventory")
);
const Test = lazy(() => import("./components/test"));
const AddMaterial = lazy(() =>
  import("./components/MaterialInventory/AddMaterial")
);
const JobLibrary = lazy(() => import("./components/job-submit/JobLibrary"));
const AddJob = lazy(() => import("./components/job-submit/AddJob"));
const JobPart = lazy(() => import("./components/job-submit/JobPart"));
const SessionModal = lazy(() => import("./components/Modal"));

function SessionCheck({ children }) {
  const navigate = useNavigate();
  const [isSessionValid, setIsSessionValid] = React.useState(true);
  const [isSessionModalOpen, setIsSessionModalOpen] = React.useState(false);

  useEffect(() => {
    const handleSession = async () => {
      const sessionExpired = checkSession(navigate);

      if (sessionExpired) {
        setIsSessionValid(false);
        setIsSessionModalOpen(true);
        startAutoCloseTimer();
      }
    };

    handleSession();

    const intervalId = setInterval(() => {
      handleSession();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  const extendSession = async () => {
    const extended = await sessionExtension();
    if (extended) {
      setIsSessionValid(true);
      setIsSessionModalOpen(false);
    } else {
      navigate("/");
    }
  };

  const handleSessionModalClose = () => {
    navigate("/");
    setIsSessionModalOpen(false);

    localStorage.clear();
  };
  const startAutoCloseTimer = () => {
    setTimeout(() => {
      if (isSessionModalOpen) {
        handleSessionModalClose(); 
      }
    }, 10000); 
  };

  if (!isSessionValid && !isSessionModalOpen) {
    return null;
  }

  return (
    <>
      {children}
      <SessionModal
        visible={isSessionModalOpen}
        onOk={handleSessionModalClose}
        onCancel={handleSessionModalClose}
        onExtend={extendSession}
      />
    </>
  );
}

function App() {
  const privilege = localStorage.getItem("privilege");

  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("startDateTime") &&
  //     !localStorage.getItem("endDateTime")
  //   ) {
  //     localStorage.clear();
  //     localStorage.setItem(
  //       "endDateTime",
  //       moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  //     );
  //     localStorage.setItem(
  //       "startDateTime",
  //       moment().subtract(1, "hours").utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  //     );
  //   }
  //   if (!localStorage.getItem("timeZone")) {
  //     localStorage.setItem(
  //       "timeZone",
  //       Intl.DateTimeFormat().resolvedOptions().timeZone
  //     );
  //   }
  // }, []);

  return (
    <Router>
      <ThemeProvider>
        <UserProvider>
          <Suspense
            fallback={
              <div className="loading-spinner">
                <div className="spinner"></div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<AuthPage />} />
              <Route
                path="/home"
                element={
                  <SessionCheck>
                    <Layout>
                      <HomePage />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/shift"
                element={
                  <SessionCheck>
                    <Layout>
                      <ShiftSchedule />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/create-schedule"
                element={
                  <SessionCheck>
                    <Layout>
                      <CreateSchedule />
                    </Layout>
                  </SessionCheck>
                }
              />
              {privilege !== "user" && (
                <Route
                  path="/user-list"
                  element={
                    <SessionCheck>
                      <Layout>
                        <UserList />
                      </Layout>
                    </SessionCheck>
                  }
                />
              )}
              {privilege !== "user" && (
                <Route
                  path="/user-device-maps"
                  element={
                    <SessionCheck>
                      <Layout>
                        <UserDeviceMaps />
                      </Layout>
                    </SessionCheck>
                  }
                />
              )}
              <Route
                path="/device-list"
                element={
                  <SessionCheck>
                    <Layout>
                      <DeviceList />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/admin-shift-view"
                element={
                  <SessionCheck>
                    <Layout>
                      <AdminShiftView />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/device-dashboard"
                element={
                  <SessionCheck>
                    <Layout>
                      <DeviceDashboard />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/main-dashboard"
                element={
                  <SessionCheck>
                    <Layout>
                      <MainDashboard />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/settings"
                element={
                  <SessionCheck>
                    <Layout>
                      <Settings />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/settings/profilesection"
                element={
                  <SessionCheck>
                    <Layout>
                      <ProfileSection />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/settings/updatetheme"
                element={
                  <SessionCheck>
                    <Layout>
                      <UpdateTheme />
                    </Layout>
                  </SessionCheck>
                }
              />
              {privilege !== "user" && (
                <Route
                  path="/add-user"
                  element={
                    <SessionCheck>
                      <Layout>
                        <AddUser />
                      </Layout>
                    </SessionCheck>
                  }
                />
              )}
              {privilege !== "user" && (
                <Route
                  path="/assign-device"
                  element={
                    <SessionCheck>
                      <Layout>
                        <AssignDevice />
                      </Layout>
                    </SessionCheck>
                  }
                />
              )}
              <Route
                path="/material-inventory"
                element={
                  <SessionCheck>
                    <Layout>
                      <MaterialInventory />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/add-material"
                element={
                  <SessionCheck>
                    <Layout>
                      <AddMaterial />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/job-library"
                element={
                  <SessionCheck>
                    <Layout>
                      <JobLibrary />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/add-job"
                element={
                  <SessionCheck>
                    <Layout>
                      <AddJob />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/test"
                element={
                  <SessionCheck>
                    <Layout>
                      <Test />
                    </Layout>
                  </SessionCheck>
                }
              />
              <Route
                path="/job-part"
                element={
                  <SessionCheck>
                    <Layout>
                      <JobPart />
                    </Layout>
                  </SessionCheck>
                }
              />
            </Routes>
          </Suspense>
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;

// constants.js

const feAddress = "http://localhost";
const beAddress = "https://emmetrics.in";
const webSocket = "ws://localhost:8000";
const request_mode = "api";
//const feAddress = 'https://www.emmetrics.in';
//const beAddress = 'https://www.emmetrics.in:8000';
//const webSocket = 'wss://www.emmetrics.in:8000';

export { feAddress, beAddress, webSocket, request_mode };

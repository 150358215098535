import { beAddress } from "./constants";
import axios from "axios";

export const checkSession = (navigate) => {
  try {
    const sessionId = localStorage.getItem("session_uid");
    const expirationTime = localStorage.getItem("expiry_datetime");
    if (!sessionId || !expirationTime) {
      navigate("/");
      return true;
    }
    const currentTimeUTC = new Date().toISOString();
    if (currentTimeUTC > expirationTime) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error:", error);
    return true;
  }
};

export const sessionExtension = async () => {
  const sessionUid = localStorage.getItem("session_uid");
  const userUid = localStorage.getItem("user_uid");
  try {
    const response = await axios.post(
      `${beAddress}/api/extend-session`,
      {},
      {
        headers: {
          "session-uid": sessionUid,
          "user-uid": userUid,
        },
      }
    );
    const jsonData = response.data;

    if (jsonData && jsonData.data && jsonData.status === "Success") {
      localStorage.setItem("session_uid", jsonData.data.session_uid);
      localStorage.setItem("user_uid", jsonData.data.user_uid);
      localStorage.setItem("expiry_datetime", jsonData.data.expiry_datetime);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching JSON:", error);
    return false;
  }
};
